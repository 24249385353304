<div class="container-fluid">
    <app-header></app-header>
    <div class="row mt-4">
        <div class="col-12">
            <h4 class="bg-light px-3 py-2 fw-normal">Best Buy
                @if(userData.role === 'Admin') {
                <span (click)="addCurrency()" class="add-user">
                    <i class="pi pi-plus px-2"></i> Add currency</span>
                }
            </h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <p-table [columns]="cols" [value]="currencies">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th>S.No.</th>
                            <th *ngFor="let col of columns">
                                {{ col.header }}
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-columns="columns">
                        <tr>
                            <td>{{i+1}}</td>
                            <td *ngFor="let col of columns">
                                {{ rowData[col.field] }}
                            </td>

                            <td>
                                @if(userData.role == 'Admin') {
                                <span class="mx-4 d-inline-block" (click)="editCurrency(rowData)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-pencil" viewBox="0 0 16 16">
                                        <path
                                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </span>

                                <span class="d-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash" viewBox="0 0 16 16">
                                        <path
                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                        <path
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                    </svg>
                                </span>
                                }
                                @else {
                                <span class="d-inline-block">
                                    <button class="btn btn-primary" (click)="bookedCurrency(rowData)">Book
                                        Currency</button>
                                </span>
                                }
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>