<div class="row">
  <div class="col-xs-12">
    <nav class="navbar sticky-top navbar-expand-lg bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="../../assets/images/7janpathforex-logo.png" style="max-height: 50px;" alt="" class="img-fluid" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          (click)="shownavbar()" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">

          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-justify"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
          </svg>

        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{ 'show': isShownavbar }">
          <ul class="navbar-nav mr-auto w-100 justify-content-end">
            @if (userInfo?.role === 'Admin') {
              <li class="nav-item active">
                  <a class="nav-link nav-link-header" routerLink="/dashboard">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-header" routerLink="/add-user">Add User</a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-header" routerLink="/best-buy">Best buy</a>
              </li>
            }
            @if (userInfo?.role === 'User') {
              <li class="nav-item active">
                  <a class="nav-link nav-link-header" routerLink="/best-buy">Home</a>
              </li>
            }
              <li class="nav-item">
                <a class="nav-link nav-link-header" routerLink="/booked-currency">Booked Currency</a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-header btn" (click)="logout()">logout</a>
              </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>
<div class="row py-4">
  <div class="col px-4">
    <span class="user-bg">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person"
        viewBox="0 0 16 16">
        <path
          d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
      </svg>
    </span>
    <span class="user-name">{{userInfo?.name}}</span>
  </div>
  <div class="col justify-content-end px-4">
    <span class="d-flex justify-content-end date ">{{currentDate}}</span>
  </div>
</div>