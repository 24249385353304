<footer class="mt-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 footer-column">
          <ul class="nav flex-column">
            <li class="nav-item">
              <span class="footer-title">Quick Links</span>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/best-buy">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/best-buy">Best Buy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/booked-currency">Booked Currency</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">Frequently asked questions</a>
            </li> -->
          </ul>
        </div>
        <div class="col-md-4 footer-column">
          <ul class="nav flex-column">
            <li class="nav-item">
              <span class="footer-title">Company</span>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Career</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <div class="col-md-4 footer-column">
          <ul class="nav flex-column">
            <li class="nav-item">
              <span class="footer-title">Contact & Support</span>
            </li>
            <li class="nav-item">
              <span class="nav-link"><i class="fas fa-phone"></i>+91 9810474232</span>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><i class="fas fa-envelope"></i>Contact us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><i class="fas fa-star"></i>Feedback & Suggestions</a>
            </li>
          </ul>
        </div>
      </div>
  
      <div class="text-center"><i class="fas fa-ellipsis-h"></i></div>
      
      <div class="row text-center">
        <div class="col-md-4 box">
          <span class="copyright quick-links">Copyright &copy; 7travelMoney.com, 2024</span>
        </div>
        <div class="col-md-4 box">
          <!-- <ul class="list-inline social-buttons">
            <li class="list-inline-item">
              <a href="#">
              <i class="fab fa-twitter"></i>
            </a>
            </li>
            <li class="list-inline-item">
              <a href="#">
              <i class="fab fa-facebook-f"></i>
            </a>
            </li>
            <li class="list-inline-item">
              <a href="#">
              <i class="fab fa-linkedin-in"></i>
            </a>
            </li>
          </ul> -->
        </div>
        <div class="col-md-4 box">
          <ul class="list-inline quick-links">
            <li class="list-inline-item">
              <a href="#">Privacy Policy</a>
            </li>
            <li class="list-inline-item">
              <a href="#">Terms of Use</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
