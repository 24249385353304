<div class="row">
    <div class="col-12 p-4">
        <form #form="ngForm" class="card p-4">
            <div class="row g-6">
                <div class="col-md-6 col-xs-12 mb-2">
                    <label class="form-label"> Currency Name</label>
                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="formData.currencyName" required
                        readonly>
                </div>
                <div class="col-md-6 col-xs-12 mb-2">
                    <label class="form-label"> Currency Symbol</label>
                    <input type="text" class="form-control" id="symbol" name="symbol" [(ngModel)]="formData.currencies.symbol"
                        required readonly>
                </div>
            </div>
            <div class="row g-6">
                <div class="col-md-6 col-xs-12 mb-2">
                    <label class="form-label"> Best Buy Rate (INR)</label>
                    <input type="text" class="form-control" id="rate" name="rate" [(ngModel)]="formData.rate" required
                        readonly>
                </div>
                <div class="col-md-6 col-xs-12 mb-2">
                    <label class="form-label">Updated Date</label>
                    <input type="text" class="form-control" id="updated_at" name="updated_at"
                        [(ngModel)]="formData.updated_at" required readonly>
                </div>
            </div>
            <div class="row g-6">
                <div class="col-md-6 col-xs-12 mb-2">
                    <label class="form-label"> Quantity</label>
                    <input type="number" class="form-control" id="quantity" name="quantity"
                        [(ngModel)]="formData.quantity" required readonly>
                </div>
                <div class="col-md-6 col-xs-12 mb-2">
                    <label class="form-label"> Calculated Amount</label>
                    <input type="number" class="form-control" id="total_amount" name="total_amount"
                        [(ngModel)]="formData.total_amount" required readonly>
                </div>
            </div>

            <div class="row g-6">
                <div class="col-md-6 col-xs-12 mb-2">
                    <label class="form-label"> Order Status</label>
                    <select [(ngModel)]="formData.status" class="form-control" [ngModelOptions]="{standalone: true}">
                        @for(option of statusList; track option.value) {
                        <option [value]="option.value">{{ option.label }}</option>
                        }
                      </select>
                </div></div>
            <div class="row g-6 my-4">
                <div class="col text-center">
                    <button type="submit" class="btn btn-primary me-2" (click)="submitForm()"
                        [disabled]="!form.valid">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="closeDialog()">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>