
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<p-toast></p-toast>
<div class="container-fluid">
<div class="row">
    <div class="col-12">
        <h4 class="bg-light px-3 py-2 fw-normal">Users <span (click)="addUser()" class="add-user">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person-fill-add add-user-icon" viewBox="0 0 16 16">
            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
          </svg> Add User</span></h4>
        
    </div>
</div>
<div class="row">
<div class="col-12">
    <div class="card">
        <p-table [columns]="cols" [value]="users">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{ col.header }}
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        {{ rowData[col.field] }}
                    </td>
                    <td><span class="mx-4 d-inline-block cursur-pointer" title="Edit" (click)="editUser(rowData)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                          </svg>
                        </span>

                        <span class="d-inline-block cursur-pointer" title="{{rowData.status}}" (click)="deleteUser(rowData)">
                            @if (rowData.status) {
                                <i class="pi pi-eye" style="font-size: 1.2rem"></i>
                            }
                            @else {
                                <i class="pi pi-eye-slash" style="font-size: 1.2rem"></i>
                            }
                        </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
</div>
</div>
<!-- <p-messages [value]="msgs" severity="info"></p-messages> -->



