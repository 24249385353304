<div class="container-fluid">
  <div class="row justify-content-center">
   <div class="col-md-8 col-lg-4 py-4">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Login</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 text-center justify-content-center d-flex ">
            <div class="login-logo">
              <img src="../../assets/images/7janpathforex-logo.png" class="img-fluid" alt="logo">
            </div>
            
          </div>
          </div>
          <div class="row">
          <div class="col-12">
            <form (ngSubmit)="onLogin()" #loginForm="ngForm">
              <div class="form-group">
                <label for="email">Email</label>
                <input type="text" id="email" name="email" [(ngModel)]="email" required>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" id="password" name="password" [(ngModel)]="password" required>
              </div>
              <div *ngIf="loginError" class="error-message">
                Incorrect username or password.
              </div>
              <button type="submit" [disabled]="!loginForm.valid">Login</button>
            </form>
          </div>
          </div>
      </div>
    </div>
   </div>
  </div>
  
</div>
  